<template>
  <validation-observer ref="simpleRules">
    <b-card>
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <validation-provider
          #default="{ errors }"
          name="Başlık"
          rules="required"
        >
          <b-form-input
            id="title"
            v-model="dataItem.title"
            placeholder="Başlık"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="Tarih"
        label-for="lesson_date"
      >
        <validation-provider
          #default="{ errors }"
          name="Tarih"
          rules="required"
        >
          <b-form-datepicker
            id="lesson_date"
            v-model="dataItem.lesson_date"
            placeholder="Tarih"
            v-bind="{labelNoDateSelected: 'Tarih',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="Metin"
        label-for="content"
      >
        <b-form-textarea
          id="content"
          v-model="dataItem.content"
          placeholder="Metin"
        />
      </b-form-group>
    </b-card>
    <b-card title="Dosya">
      <b-row>
        <b-col
          v-if="dataItem.file"
          cols="2"
        >
          <b-button
            variant="primary"
            :href="folderUrl + dataItem.file"
            target="_blank"
            block
          >
            <FeatherIcon
              icon="DownloadIcon"
              size="48"
            />
            <br>
            Dosya İndir
          </b-button>
        </b-col>
        <b-col>
          <b-form-group
            label="Dosya Yükle"
            label-for="file"
          >
            <validation-provider
              #default="{ errors }"
              name="Dosya Seçin"
              :rules="!dataItem.file? 'required' : null"
            >
              <b-form-file
                id="file"
                v-model="dataItem.upload_doc"
                placeholder="Bir dosya seçin veya buraya bırakın..."
                drop-placeholder="Dosyayı buraya bırakın..."
                browse-text="Dosya Seçin"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </validation-observer>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormGroup,
  BButton,
  BRow,
  BCol, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'Edit',
  components: {
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BButton,
    BRow,
    BCol,
  },
  data() {
    return {
      required,
      submitStatus: false,
      folderUrl: null,
    }
  },
  computed: {
    dataItem() {
      const data = this.$store.getters['lessonsLearned/dataItem']
      if (data.file) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.folderUrl = `${this.$store.state.app.baseURL}/media/portal/lessons_learned/`
      }
      return data
    },
    saveStatus() {
      return this.$store.getters['lessonsLearned/dataSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getDocument()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  beforeCreate() {
    this.$store.commit('lessonsLearned/RESET_DATA_ITEM')
  },
  created() {
    localize('tr')
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('lessonsLearned/getData', this.$route.params.id)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('lessonsLearned/saveData', this.dataItem)
            .then(response => {
              if (response.status) {
                this.$router.push({ name: 'LessonsLearned' })
              } else {
                this.submitStatus = false
                this.sweetAlert({
                  icon: 'XIcon',
                  title: 'Hata oluştu.',
                  text: response.message,
                  html: '',
                  buttonText: 'Tekrar dene',
                })
              }
            })
        }
      })
    },
  },
}
</script>
